import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import ContactForm from "../pages/contact/form";

import { motion, useAnimation } from "framer-motion";
import { InView, useInView } from "react-intersection-observer";

export const ClientServicesTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="alt-hero" id="about">
      <div className="container">
        <div className="why">
          <InView threshold={0.2}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, transform: "translate(-50px)" }}
                animate={
                  inView
                    ? { opacity: 1, transform: "translate(0px)" }
                    : { opacity: 1 }
                }
                transition={{
                  type: "spring",
                  stiffness: 100,
                  duration: 3,
                  delay: 0.25,
                }}
              >              <h3
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="4"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
                Client Services
                </h3>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="title">
          <InView threshold={0.2}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, transform: "translate(-50px)" }}
                animate={
                  inView
                    ? { opacity: 1, transform: "translate(0px)" }
                    : { opacity: 1 }
                }
                transition={{
                  type: "spring",
                  stiffness: 100,
                  duration: 3,
                  delay: 0.25,
                }}
              >              <h2
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
                Connecting the right talent with thriving businesses, globally.
                Within the User Experience, Creative and Technology industries.</h2>
              </motion.div>
            )}
          </InView>
        </div>
      </div>
    </section>

    <section>
      <div className="services">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.5,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/experience.svg">
                <source src="../img/experience.webm" type="video/webm" />
              </video>

              <div className="service-title">UX Director</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.6,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/product.svg">
                <source src="../img/product.webm" type="video/webm" />
              </video>
              <div className="service-title">product design</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.7,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/ux.svg">
                <source src="../img/ux.webm" type="video/webm" />
              </video>
              <div className="service-title">user experience</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.8,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/copywrite.svg">
                <source src="../img/copywrite.webm" type="video/webm" />
              </video>
              <div className="service-title">copywriting</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.9,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/service.svg">
                <source src="../img/service.webm" type="video/webm" />
              </video>
              <div className="service-title">service design</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.5,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/visual.svg">
                <source src="../img/visual.webm" type="video/webm" />
              </video>
              <div className="service-title">visual design</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.6,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/motion.svg">
                <source src="../img/motion.webm" type="video/webm" />
              </video>
              <div className="service-title">motion graphics</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.7,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/creative.svg">
                <source src="../img/creative.webm" type="video/webm" />
              </video>
              <div className="service-title">creative director</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.8,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/design.svg">
                <source src="../img/design.webm" type="video/webm" />
              </video>
              <div className="service-title">design director</div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.9,
              }}
              className="item"
            >
              <video loop autoPlay muted poster="../img/art.svg">
                <source src="../img/art.webm" type="video/webm" />
              </video>
              <div className="service-title">art director</div>
            </motion.div>
          )}
        </InView>
      </div>
    </section>

    <section className="home-where">
      <div className="role forceblack">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, transform: "translate(-50px)" }}
              animate={
                inView
                  ? { opacity: 1, transform: "translate(0px)" }
                  : { opacity: 1 }
              }
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.25,
              }}
            >
              Contract
            </motion.h2>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, transform: "translate(-50px)" }}
              animate={
                inView
                  ? { opacity: 1, transform: "translate(0px)" }
                  : { opacity: 1 }
              }
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.25,
              }}
              className="paddingfix"
            >
              
              Whether you are looking for someone to start immediately, or just
              additional hands on a project; our contract services are designed
              to ensure any gaps in personnel does not impact your business as a
              whole.
              <br />
              <br />
              We provide you with the right fit your business. Our local and
              global network is always expanding, in order to find you the best
              talent.
            </motion.p>
          )}
        </InView>
      </div>
      <div className="role forceblack">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, transform: "translate(50px)" }}
              animate={
                inView
                  ? { opacity: 1, transform: "translate(0px)" }
                  : { opacity: 1 }
              }
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.25,
              }}
            >
              Permanent
            </motion.h2>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, transform: "translate(50px)" }}
              animate={
                inView
                  ? { opacity: 1, transform: "translate(0px)" }
                  : { opacity: 1 }
              }
              transition={{
                type: "spring",
                stiffness: 100,
                duration: 3,
                delay: 0.25,
              }}
              className="paddingfix"
            >
              We understand the need to be efficient, flexible and resourceful
              in order to fill individual roles as they emerge within the
              business.
              <br />
              <br />
              We work in two ways: in collaboration with you, we understand the
              details of the role and your budget, as well as utilizing our
              extensive network to hire the right creative.
            </motion.p>
          )}
        </InView>
      </div>
    </section>

    <section>
      <ContactForm />
    </section>
  </div>
);

ClientServicesTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ClientServices = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ClientServicesTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

ClientServices.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ClientServices;

export const pageQuery = graphql`
  query ClientServicesTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
